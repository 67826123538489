import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";
import { get } from "lodash";

function ProgressbarItem({
  className,
  children,
  path,
  pages,
  isComplete,
  isActive,
  ...rest
}) {
  const baseClassName =
    "py-3 pr-4 pl-3 inline-flex border-l-8 text-sm leading-loose border-seafoam";
  const activeClassName = "bg-just-black text-white border-just-black";
  const notStartedClassName = "text-just-black-64 border-just-black-12";
  return (
    <div
      className={cs(baseClassName, {
        [activeClassName]: isActive,
        [notStartedClassName]: !isComplete
      })}
      {...rest}
    >
      <span className="mr-4">{children}</span>
      <span className="ml-auto h-6 w-6 flex items-center">
        {isComplete && ( //(isCompleted || isCurrentPageCompletedAndValid) if they want tick on adviser bar on progress bar
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="14"
            viewBox="0 0 18 14"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M-3-5h24v24H-3z" />
              <path
                fill="#2FCFA0"
                d="M6 11.17L1.83 7 .41 8.41 6 14 18 2 16.59.59z"
              />
            </g>
          </svg>
        )}
      </span>
    </div>
  );
}

ProgressbarItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  path: PropTypes.string
};

function ProgressbarWrapper(props) {
  return <div className="flex flex-col bg-white" {...props} />;
}

function Sticky(props) {
  return <div className="sticky ProgressBar" {...props} />;
}

export default function ProgressBar({ currentPath, properties }) {
  const pages = [{ title: "Applicant(s) details", path: "applicants" }];

  if (properties && get(properties, "isInterestedInEnhancedLtv") === true) {
    pages.push({ title: "Health & lifestyle", path: "health-and-lifestyle" });
  }

  pages.push(
    { title: "Property details", path: "property" },
    { title: "Borrowing", path: "borrowing" }
  );

  const activePageIndex = pages.findIndex(page => page.path === currentPath);

  const pagesWithStatuses = pages.map((page, i) => {
    return {
      ...page,
      isComplete: i < activePageIndex,
      isActive: activePageIndex === i
    };
  });

  return (
    <Sticky>
      <ProgressbarWrapper>
        {pagesWithStatuses.map((page, i) => {
          return (
            <ProgressbarItem
              path={page.path}
              key={page.path}
              isActive={page.isActive}
              isComplete={page.isComplete}
            >
              {i + 1}. {page.title}
            </ProgressbarItem>
          );
        })}
      </ProgressbarWrapper>
    </Sticky>
  );
}
