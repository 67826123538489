import log from "./log";

export function combineInitialAndDefaultFormValues(pageState, defaultValues) {
  return {
    ...defaultValues,
    ...pageState
  };
}

export const formatPercentage = (value, options) => {
  if (value != null) {
    try {
      return new Intl.NumberFormat("en-UK", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options,
        style: "percent"
      }).format(value / 100); // takes percentage value not fraction
    } catch (e) {
      log.error("formatter error", e);
    }
  }

  return "-";
};

export const formatCurrency = (value, options) => {
  if (value != null) {
    try {
      return new Intl.NumberFormat("en-UK", {
        currency: "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
        style: "currency"
      }).format(value);
    } catch (e) {
      log.error("formatter error", e);
    }
  }

  return "-";
};

export function isWindowDefined() {
  if (typeof window !== "undefined") {
    return true;
  }
  return false;
}

export function hasExpiredToken(user) {
  return !user || new Date(user.expires_at * 1000) < new Date();
}

export function userHasComeFromAccount() {
  return document.referrer.indexOf(process.env.ACCOUNT_APP_URL) === 0;
}
