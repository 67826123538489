import React, { useEffect } from "react";
import { useFormikContext } from "formik";

export function useShouldShowPageError() {
  const { isValid, isSubmitting, submitCount } = useFormikContext();
  const hasTriedToSubmit = submitCount > 0;

  useEffect(() => {
    if (isSubmitting) {
      if (!isValid) {
        window.scrollTo(0, 0);
      }
    }
  }, [isSubmitting, isValid]);

  return hasTriedToSubmit && !isValid;
}

export function ConditionalPageError() {
  const shouldShowPageError = useShouldShowPageError();
  if (!shouldShowPageError) {
    return null;
  }

  return <PageError />;
}

class PageError extends React.Component {
  render() {
    const { heading, children } = this.props;

    return (
      <div className="PageErrorMessage p-6 flex mb-12 bg-tangerine-50">
        <svg
          className="mr-4 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M-2-2h24v24H-2z" />
            <path
              fill="#000"
              d="M9 13h2v2H9v-2zm0-8h2v6H9V5zm.99-5C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zM10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
          </g>
        </svg>
        <div>
          <div className={`font-bold ${children && "mb-2"}`}>{heading}</div>
          {children}
        </div>
      </div>
    );
  }
}

PageError.defaultProps = {
  heading: "There was an error when submitting the details",
  children:
    "Please see the highlighted area below for what went wrong and how to solve it."
};

export default PageError;
