import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export default function RadioButton({
  children,
  isError,
  className,
  ...restProps
}) {
  const radioClass = cn("radio", { "radio--error": isError }, className);

  return (
    <div className={radioClass}>
      <input type="radio" {...restProps} />
      <label htmlFor={restProps.id}>{children}</label>
    </div>
  );
}

RadioButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isError: PropTypes.bool
};
