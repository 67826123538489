import React from "react";
import "./user-menu.css";

import createAuthService from "../../../common/auth/auth-service";
import useUser from "../../../common/use-user";
import MenuPopup from "./../menu-popup/menu-popup";
import { navigate } from "gatsby";

function Popup({ hide }) {
  const profileHandler = async () => {
    await navigate(`${process.env.ACCOUNT_APP_URL}`);
    hide();
  };
  const logoutHandler = async () => {
    const authService = createAuthService();
    await authService.logout();
    hide();
  };
  return (
    <div className="profile-popup">
      <div
        tabIndex="-1"
        role="button"
        className="profile-item"
        onMouseDown={profileHandler}
        onKeyDown={() => {}}
      >
        <i className="h-6 w-6 icn icn-account-circle" />
        <span className="ml-4 text-base text-just-black">My Account</span>
      </div>
      <hr className="keyline" />
      <div
        tabIndex="-1"
        role="button"
        className="profile-item"
        onMouseDown={logoutHandler}
        onKeyDown={() => {}}
      >
        <i className="h-6 w-6 icn icn-power-settings" />
        <span className="ml-4 text-base text-just-black">Logout</span>
      </div>
    </div>
  );
}

function UserMenu() {
  const user = useUser({ suspense: false });

  if (!user) {
    return null;
  }

  return (
    <MenuPopup popup={<Popup />}>
      <div className="user-menu flex items-center cursor-pointer">
        <i className="h-6 w-6 icn icn-account-circle" />
        <span className="mx-4 hover:cursor-pointer">{user.profile.name}</span>
        <i className="w-6 h-6 icn icn-chevron-down" />
      </div>
    </MenuPopup>
  );
}

export default UserMenu;
