import React from "react";
import cn from "classnames";

import "./file-icon.css";

export default function FileIcon({ className, text }) {
  const width = 22;
  const height = 22;
  const lineOffset = 4;
  const strokeWidth = 2;
  const rectOffset = 4;

  const Line = ({ x1, y1, x2, y2 }) => {
    return (
      <line
        className="line"
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        style={{ strokeWidth: strokeWidth }}
      />
    );
  };

  return (
    <svg
      className={cn("inline file-type mr-4 mt-1", className)}
      style={{ width: width, height: height }}
    >
      <Line
        x1={strokeWidth / 2}
        y1={lineOffset}
        x2={strokeWidth / 2}
        y2={height - strokeWidth}
      />

      <Line
        x1={strokeWidth}
        y1={height - strokeWidth / 2}
        x2={width - lineOffset}
        y2={height - strokeWidth / 2}
      />

      <rect
        className="rect"
        width={width - rectOffset}
        height={height - rectOffset}
        rx="2"
        ry="2"
        x={rectOffset}
        y="0"
      />
      <text x="5.5" y="12" className="file-type-text">
        {text}
      </text>
    </svg>
  );
}
