import React from "react";
import cn from "classnames";
import { classesFromProps } from "../utils";
import { omit } from "lodash";

const typographicalClassMapping = {
  title1: "title-1",
  title2: "title-2",
  subtitle1: "subtitle-1",
  subtitle2: "subtitle-2",
  note: "note",
  secondary: "secondary",
  bold: "font-bold"
};

export default function Typography({ component, className, ...restProps }) {
  return React.createElement(component, {
    className: cn(
      classesFromProps(restProps, typographicalClassMapping),
      className
    ),
    ...omit(restProps, Object.keys(typographicalClassMapping))
  });
}

Typography.defaultProps = {
  component: "span"
};
