import React from "react";
import { formatPercentage, formatCurrency } from "../../common/utils";
import { Message } from ".";

export default function BorrowingPageMessage({
  maximumLoanAmount,
  ltvPercentage
}) {
  return (
    <Message>
      Based on the information your applicant(s) are eligible for:{" "}
      <span className="font-bold">
        Maximum:{" "}
        {formatCurrency(maximumLoanAmount, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        })}
      </span>
      <span>{` (LTV: ${formatPercentage(ltvPercentage, {
        maximumFractionDigits: 0
      })}) `}</span>
    </Message>
  );
}
