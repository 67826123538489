import React from "react";
import cn from "classnames";

function ModifiedInput({
  convert,
  maxLength,
  className,
  pattern,
  onChange,
  ...restProps
}) {
  const { isError, ...restPropsWithoutError } = restProps;
  const input = (
    <input
      onChange={onChange}
      maxLength={maxLength}
      type="text" // https://github.com/facebook/react/issues/13752
      pattern={pattern}
      className={cn("input", className)}
      {...restPropsWithoutError}
    />
  );
  return convert ? convert(input) : input;
}

export default function Input({
  type,
  hasError,
  className,
  maxLength,
  onChange,
  pattern,
  convert,
  ...restProps
}) {
  return (
    <ModifiedInput
      convert={convert}
      onChange={onChange}
      maxLength={maxLength}
      type="text" // https://github.com/facebook/react/issues/13752
      pattern={pattern}
      className={cn(className, hasError && "input--error")}
      {...restProps}
    />
  );
}
