import React from "react";

export const omit = (keys, obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key))
  );

export function classesFromProps(props, mapping) {
  let classNames = {};

  for (const [propKey, className] of Object.entries(mapping)) {
    classNames[className] = !!props[propKey];
  }

  return classNames;
}

export function recursiveMap(children, fn) {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: recursiveMap(child.props.children, fn)
      });
    }

    return fn(child);
  });
}

export function compareReactElements(left, right) {
  return left.type === right || left.type.displayName === right.name;
}
