import React from "react";
import cn from "classnames";
import { classesFromProps } from "../utils";
import { omit } from "lodash";

const buttonClassMapping = {
  outlined: "button--outlined",
  dark: "button--dark",
  text: "button--text"
};

export default function Button({ component, className, ...restProps }) {
  return React.createElement(component, {
    className: cn(
      "button",
      classesFromProps(restProps, buttonClassMapping),
      className
    ),
    ...omit(restProps, Object.keys(buttonClassMapping))
  });
}

Button.defaultProps = {
  component: "button"
};
