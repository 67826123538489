import log from "../common/log";
import { name, description, version } from "../../package.json";

log.info("App Running", {
  name,
  description,
  version,
  environment: process.env.GATSBY_ACTIVE_ENV,
  build: process.env.CIRCLE_BUILD_NUM
});
