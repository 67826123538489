import React from "react";
import PropTypes from "prop-types";

export default function Label({ htmlFor, ...restProps }) {
  return <label className="label" htmlFor={htmlFor} {...restProps} />;
}

Label.propTypes = {
  htmlFor: PropTypes.string
};
