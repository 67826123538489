import "../common/app-running";
import "../common/global-css";
import React from "react";
import PropTypes from "prop-types";
import { Footer, Header } from "./base";
import PageLoading from "./loading";

export function ErrorLayout({ children }) {
  return (
    <div className="flex">
      <div className="min-h-screen flex flex-col w-full">
        <Header iconOnly />
        <main className="flex-auto md:flex-row mt-8 md:mt-12 mb-12 md:mb-24 lg:mb-30">
          <div className="container">
            <div className="columns">
              <div className="column w-full">{children}</div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export function CaseLayout({ children }) {
  return (
    <div className="flex">
      <div className="min-h-screen flex flex-col bg-black-light w-full">
        <Header />
        <main className="flex-auto md:flex-row">{children}</main>
        <Footer />
      </div>
    </div>
  );
}

export function LayoutWithLoading({ title, subtitle }) {
  return (
    <FormLayout>
      <PageLoading title={title} subtitle={subtitle} />
    </FormLayout>
  );
}

export default function FormLayout({ children }) {
  return (
    <div className="flex">
      <div className="min-h-screen flex flex-col bg-black-light w-full">
        <Header />
        <main className="flex-auto md:flex-row mt-8 md:mt-12 mb-12 md:mb-24 lg:mb-30">
          <div className="container">{children}</div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

FormLayout.propTypes = {
  children: PropTypes.node
};

FormLayout.defaultProps = {
  children: ""
};
