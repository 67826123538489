import React from "react";

export default function Footer() {
  return (
    <footer className="border-t border-just-black-12 flex-none text-sm leading-loose bg-white flex-shrink-0">
      <div className="container py-8 md:py-12">
        <div className="columns">
          <div className="column w-full">{`© ${new Date().getFullYear()} JUST Group Plc.`}</div>
        </div>
        <div className="columns mt-4 md:mt-8">
          <div className="column flex flex-col md:flex-row">
            <div className="link md:pr-4">
              <a href="https://www.justadviser.com/terms-of-business/">
                Terms &amp; Conditions
              </a>
            </div>
            <div className="link mt-4 md:mt-0 md:pr-4 md:pl-4 md:border-r md:border-l">
              <a href="https://www.justadviser.com/privacy-policy/">
                Privacy Policy
              </a>
            </div>
            <div className="link mt-4 md:mt-0 md:pl-4">
              <a href="https://www.justadviser.com/cookie-policy/">
                Cookie Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
