import React from "react";
import { Link } from "gatsby";

function NavbarLink({ to, ...props }) {
  return (
    <Link
      to={to}
      className="h-full no-underline text-just-black border-just-black inline-flex flex-col justify-center mr-4"
      activeClassName="pt-1 font-bold"
    >
      <div {...props}></div>
    </Link>
  );
}

export default function NavBar() {
  return (
    <>
      <NavbarLink to="/app/cases">Cases</NavbarLink>
    </>
  );
}
