import React from "react";
import PropTypes from "prop-types";

function ErrorMessage({ children }) {
  return (
    <span className="input-message input-message--error">{children} </span>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.node
};

export default ErrorMessage;
