import React from "react";

function Paragraph({ title, value, show = true }) {
  return show && value ? (
    <div className="sm:flex-col md:flex mb-2">
      <div className="md:w-1/2">
        <div className="md:w-7/12">{title}</div>
      </div>
      <div className="flex">
        <span className="font-bold self-end">{value}</span>
      </div>
    </div>
  ) : null;
}
export default Paragraph;
