import React, { useEffect, useRef, useState } from "react";
import "./menu-popup.css";

export function useEvent(event, handler, passive = false) {
  useEffect(() => {
    window.addEventListener(event, handler, passive);

    return () => {
      window.removeEventListener(event, handler);
    };
  });
}

function ResetDimensions(
  targetRef,
  setDimensions,
  popupRect,
  setPopupPosition
) {
  if (targetRef.current) {
    const newDimensions = {
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight,
      left: targetRef.current.offsetLeft,
      top: targetRef.current.offsetTop
    };

    setPopupPosition({
      left: newDimensions.left + newDimensions.width - popupRect.width,
      top: newDimensions.top + newDimensions.height + 38
    });
    setDimensions(newDimensions);
  }
}

function updatePopupRect(el, setPopupRect, setPopupPosition, parentDimensions) {
  setPopupRect(x => {
    if (!el) {
      return x;
    }

    const clientRect = el.getBoundingClientRect();
    const rect = {
      width: clientRect.width,
      height: clientRect.height
    };

    if (rect.width === x.width && rect.height === x.height) {
      return x;
    }

    setPopupPosition({
      left: parentDimensions.left + parentDimensions.width - rect.width,
      top: parentDimensions.top + parentDimensions.height + 35
    });

    return rect;
  });
}

export default function MenuPopup({ children, popup, pinned }) {
  const targetRef = useRef();
  const [parentDimensions, setParentDimensions] = useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0
  });
  const [popupRect, setPopupRect] = useState({
    width: 0,
    height: 0
  });
  const [popupPosition, setPopupPosition] = useState({
    left: 0,
    top: 0
  });

  useEffect(() => {
    ResetDimensions(
      targetRef,
      setParentDimensions,
      popupRect,
      setPopupPosition
    );
  }, [
    popupRect.width,
    popupRect.height,
    parentDimensions.top,
    parentDimensions.width,
    parentDimensions.left,
    parentDimensions.height
  ]);

  useEvent("resize", () =>
    ResetDimensions(targetRef, setParentDimensions, popupRect, setPopupPosition)
  );

  const [open, setOpen] = useState(false);

  const newPopup = React.cloneElement(popup, {
    ...popup.props,
    hide: () => setOpen(false)
  });

  return (
    <>
      <div
        tabIndex="-1"
        role="button"
        ref={targetRef}
        className="parent-body"
        open={open}
        onClick={() => setOpen(!open)}
        onKeyDown={() => {}}
      >
        {children}
      </div>
      {open && (
        <>
          <div
            ref={input => input && input.focus()}
            tabIndex="-1"
            className="popup"
            style={{ left: popupPosition.left, top: popupPosition.top }}
            onBlur={() => {
              if (!pinned) {
                setOpen(false);
              }
            }}
          >
            <div
              className="inner"
              ref={el =>
                updatePopupRect(
                  el,
                  setPopupRect,
                  setPopupPosition,
                  parentDimensions
                )
              }
            >
              {newPopup}
            </div>
          </div>
        </>
      )}
    </>
  );
}
