import React from "react";
import { Spinner } from ".";

export default function PageLoading({ title, subtitle }) {
  return (
    <>
      <div className="mx-auto my-6 h-16 w-16">
        <Spinner />
      </div>
      <div className="subtitle-2 text-center">
        <span className="font-bold">{title}</span>
      </div>
      <div className="note text-center mt-4">
        <span className="text-just-black-64">{subtitle}</span>
      </div>
    </>
  );
}
