import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

function PlaceholderOption({ children }) {
  return (
    <option disabled value="">
      {children}
    </option>
  );
}

PlaceholderOption.propTypes = {
  children: PropTypes.node
};

export default function Dropdown({
  className,
  isError,
  placeholder,
  children,
  ...restProps
}) {
  const dropdownClass = cs(
    "dropdown",
    { "dropdown--error": isError },
    className
  );
  return (
    <select className={dropdownClass} {...restProps}>
      {placeholder && <PlaceholderOption>{placeholder}</PlaceholderOption>}
      {children}
    </select>
  );
}

Dropdown.propTypes = {
  className: PropTypes.string,
  isError: PropTypes.bool,
  placeholder: PropTypes.string,
  children: PropTypes.node
};
