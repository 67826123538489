import React from "react";
import "./customInputGroup.css";

import cn from "classnames";

const InputGroupPrepend = ({ children }) => {
  return <div className="custom-input-group-prepend">{children}</div>;
};

const InputGroupAppend = ({ children }) => {
  return <div className="custom-input-group-append">{children}</div>;
};

const InputGroupText = ({ children, className }) => {
  return (
    <span className={cn("custom-input-group-text", className)}>{children}</span>
  );
};

const InputGroup = ({
  children,
  prepend,
  append,
  className,
  hasError,
  grouped
}) => {
  const convert = (input, inputClassName, inputHasError) => {
    const errorClassName = cn(
      hasError || inputHasError ? "input--error" : null
    );
    return (
      <div className={cn("custom-input-group", className)}>
        {prepend && (
          <InputGroupPrepend>
            <InputGroupText className={errorClassName}>
              {prepend}
            </InputGroupText>
          </InputGroupPrepend>
        )}

        {React.cloneElement(input, {
          ...input.props,
          className: cn(input.props.className, inputClassName, {
            borderRadiusAppend: append,
            borderRadiusPrepend: prepend
          })
        })}

        {append && (
          <InputGroupAppend>
            <InputGroupText className={errorClassName}>{append}</InputGroupText>
          </InputGroupAppend>
        )}
      </div>
    );
  };

  const newChildren = React.Children.map(children, x => {
    return React.cloneElement(x, {
      ...x.props,
      grouped,
      hasError,
      convert
    });
  });

  return <>{newChildren}</>;
};

export default InputGroup;
