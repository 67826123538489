import React from "react";
import { Link } from "gatsby";
import "./back-link.css";

export default function BackLink({ path, onClick, children }) {
  return (
    <Link
      to={path}
      className="link back-link inline-flex flex-col justify-center"
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
