import React from "react";
import cn from "classnames";
import { formatCurrency, formatPercentage } from "../../../common/utils";

function getServicing(type) {
  switch (type) {
    case "Rollup":
      return "Roll up";
    case "Interest Serviced":
      return "Interest";
    default:
      return "Roll up and Interest";
  }
}

const defaultColumnClasses = {
  name: "md:pr-4 w-full md:w-1/2 lg:w-1/2 xl:w-4/12",
  initialAdvanceAmount: "lg:pr-4 w-full md:w-1/2 lg:w-1/4 xl:w-2/12",
  drawdownReserveAmount:
    "md:pr-4 lg:pr-0 xl:pr-4 w-full md:w-1/2 lg:w-1/4 xl:w-2/12",
  interestServicedPercentage: "lg:pr-4 w-full md:w-1/2 lg:w-1/2 xl:w-2/12",
  mer: "md:pr-4 w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/12",
  aer: "w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/12"
};

export default function ProductInfoTable({
  columnClasses,
  code,
  name,
  cashbackAmount,
  initialAdvanceAmount,
  ltvPercentage,
  drawdownReserveAmount,
  interestServicedPercentage,
  planType,
  mer,
  aer
}) {
  const classes = {
    ...defaultColumnClasses,
    ...columnClasses
  };

  return (
    <>
      <div className={cn("my-2", classes.name)}>
        <ProductCardHeading>Just for you lifetime mortgage</ProductCardHeading>
        <ProductCardBody>
          <ProductCardStrong>{code}</ProductCardStrong> {"- "}
          {name}
        </ProductCardBody>
        <ProductCardSecondary>
          Cashback amount:{" "}
          <ProductCardStrong>
            {formatCurrency(cashbackAmount)}
          </ProductCardStrong>
        </ProductCardSecondary>
      </div>
      <div className={cn("my-2", classes.initialAdvanceAmount)}>
        <ProductCardHeading>Initial advance</ProductCardHeading>
        <ProductCardBody>
          <ProductCardStrong>
            {formatCurrency(initialAdvanceAmount)}
          </ProductCardStrong>
        </ProductCardBody>
        <ProductCardSecondary>
          LTV:{" "}
          <ProductCardStrong>
            {formatPercentage(ltvPercentage, {
              maximumFractionDigits: 0
            })}
          </ProductCardStrong>
        </ProductCardSecondary>
      </div>
      <div className={cn("my-2", classes.drawdownReserveAmount)}>
        <ProductCardHeading>Drawdown reserve</ProductCardHeading>
        <ProductCardBody>
          <ProductCardStrong>
            {formatCurrency(drawdownReserveAmount)}
          </ProductCardStrong>
        </ProductCardBody>
      </div>
      <div className={cn("my-2", classes.interestServicedPercentage)}>
        <ProductCardHeading>Product type</ProductCardHeading>
        <ProductCardBody>
          <ProductCardStrong>
            {formatPercentage(interestServicedPercentage)}
          </ProductCardStrong>
        </ProductCardBody>
        <ProductCardSecondary>
          <ProductCardStrong>{getServicing(planType)}</ProductCardStrong>
        </ProductCardSecondary>
      </div>
      <div className={cn("my-2", classes.mer)}>
        <ProductCardHeading>MER</ProductCardHeading>
        <ProductCardBody>
          <ProductCardStrong>{formatPercentage(mer)}</ProductCardStrong>
        </ProductCardBody>
      </div>
      <div className={cn("my-2", classes.aer)}>
        <ProductCardHeading>AER</ProductCardHeading>
        <ProductCardBody>
          <ProductCardStrong>{formatPercentage(aer)}</ProductCardStrong>
        </ProductCardBody>
      </div>
    </>
  );
}

const ProductCardHeading = ({ children }) => {
  return (
    <span className="block text-coral-dark text-xs leading-snug md:mb-2">
      {children}
    </span>
  );
};

const ProductCardStrong = ({ children }) => {
  return <strong className="font-bold">{children}</strong>;
};

const ProductCardBody = ({ children }) => {
  return <span className="md:text-lg break-words">{children}</span>;
};

const ProductCardSecondary = ({ children }) => {
  return (
    <span className="block text-just-black-64 text-xs leading-snug">
      {children}
    </span>
  );
};
