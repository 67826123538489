import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export default function Checkbox({
  children,
  isError,
  className,
  ...restProps
}) {
  const checkboxClass = cn(
    "checkbox",
    { "checkbox--error": isError },
    className
  );

  return (
    <div className={checkboxClass}>
      <input type="checkbox" {...restProps} />
      <label htmlFor={restProps.id}>{children}</label>
    </div>
  );
}

Checkbox.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any.isRequired
};
