import React from "react";

export default function NavigationButtonsWrapper(props) {
  return (
    <div
      className="flex flex-col md:flex-row-reverse justify-between"
      {...props}
    ></div>
  );
}
