import createAuthService from "../common/auth/auth-service";
import useSwr from "swr";

export default function useUser(config) {
  const defaultConfig = {
    suspense: true,
    shouldRetryOnError: false
  };
  const authService = createAuthService();
  const { data: user } = useSwr("getUser", () => authService.getUser(), {
    ...defaultConfig,
    ...config
  });
  return user;
}
