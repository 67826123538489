import log from "loglevel";
import remote from "loglevel-plugin-remote";
import { isWindowDefined } from "./utils";
import createAuthService from "../common/auth/auth-service";

if (process.env.LOG_LEVEL) {
  log.setDefaultLevel(process.env.LOG_LEVEL);
}

if (isWindowDefined()) {
  const authService = createAuthService();

  authService.getUser().then(user => {
    if (user) {
      remote.setToken(user.access_token);
    }
  });

  const config = {
    url: `${process.env.ADVISER_BASE_URL}/logger`,
    method: "POST",
    token: undefined,
    interval: process.env.LOG_INTERVAL,
    timestamp: () => new Date().toISOString(),
    format: remote.json
  };

  remote.apply(log, config);
  window.setLogLevel = log.setLevel;
}

export default log;
